import { Button } from '@remarkable/ark-web';
import { CartInteraction } from 'ampli-types';
import cx from 'classnames';
import { X } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectModal } from 'src/components/Cart/MpfCart/ConnectModal';
import { useGTMProductsData } from 'src/contexts/GTMProductsDataContext';
import { useReduxCart } from 'src/hooks/cart/useCart';
import { useCountryData } from 'src/hooks/useCountryData';
import { setCartVisible } from 'src/redux/actions/storeActions2';
import { State } from 'src/redux/reducers';
import { EECEventKeys, getShouldOmitVATFromTracking, pushEECcheckoutStep } from 'src/services/googleTagManager';
import { tracker } from 'src/services/tracker';
import { ComponentLocations } from 'src/services/tracking/eventTypes';
import { getTrackingProducts, getTrackingProductsTotal } from 'src/services/tracking/utils';

import { getCurrencyForCountry } from 'src/helpers/formattingHelpers';
import { getCurrentCountryDataEntry, getVATPercentageFromCountryDataEntry } from 'src/helpers/storeHelpers';
import { useLockScroll } from 'src/hooks/useLockScroll';
import ClientOnly from '../ClientOnly';
import { CartContent } from './CartContent/CartContent';

const Cart: React.FC = () => {
  const cart = useReduxCart();
  const ref = useRef<HTMLDivElement>(null);

  const [showCartButton, setShowCartButton] = useState<Element>();

  const dispatch = useDispatch();
  const showCart = useSelector((state: State) => state.store2.cartVisible);

  const { country, region, currency } = useCountryData();
  const returnFocus = () => {
    const cartButtonElement = showCartButton as HTMLButtonElement;
    setTimeout(() => cartButtonElement.focus(), 0);
  };

  useEffect(() => {
    // Close cart if Escape is pressed
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && showCart) {
        dispatch(setCartVisible(false));
      }
    };

    document.addEventListener('keydown', escFunction);

    const cartButton = document.querySelector('#show-cart');

    if (cartButton) {
      setShowCartButton(cartButton);
    } else if (cart.data && cart.data.items.length > 0) {
      // Only throw error if button is suppose to be visible
      throw new Error('Cannot enable focus lock on cart because cart button was not found');
    }

    return () => document.removeEventListener('keydown', escFunction);
  }, [cart.data, dispatch, showCart]);

  const gtmProductsData = useGTMProductsData();
  const trackingProducts = getTrackingProducts(cart.data, gtmProductsData);

  useEffect(() => {
    if (showCart && country && !!cart.data) {
      //This seems to be logic to open cart when the cart has content.... Thus not relevant for Amplitude tracking as a checkout step
      const currencyDetails = getCurrencyForCountry(country);
      const shouldOmitVATFromTracking = getShouldOmitVATFromTracking();
      const VATPercentage = getVATPercentageFromCountryDataEntry(getCurrentCountryDataEntry(country), region);

      pushEECcheckoutStep({
        cart: cart.data,
        country,
        currencyDetails,
        VATPercentage,
        shouldOmitVATFromTracking,
        county: region,
        step: 1,
        eventKey: EECEventKeys.EEC_CHECKOUT_STEP_1,
        gtmProductsData,
      });
    }
  }, [country, showCart, !!cart.data, gtmProductsData]);

  const [showModal, setShowModal] = useState(false);
  useLockScroll(showCart);

  return (
    <>
      <ClientOnly>
        <FocusLock disabled={!showCart} autoFocus={false} onDeactivation={returnFocus}>
          <div
            ref={ref}
            className={cx(
              'fixed top-0 left-0 w-screen h-full pointer-events-none bg-transparent transition-colors ease-out duration-300 z-[1000]',
              { 'pointer-events-auto bg-grayscale-black/[0.7]': showCart }
            )}
            aria-hidden={!showCart}
            onClick={(e) => {
              if (ref.current === e.target) {
                dispatch(setCartVisible(false));
                tracker.trackEvent(
                  new CartInteraction({
                    action: 'close',
                    component_location: ComponentLocations.CART.CLICK_OUTSIDE,
                    cart_products: trackingProducts,
                    cart_value: getTrackingProductsTotal(trackingProducts),
                  })
                );
              }
            }}
            //This seems to be triggered both when deleting item AND closing the cart?????????
            // {...setTrackClick({
            //   event_name: AmplitudeEventNames.CART_INTERACTION,
            //   action: 'close',
            //   component_location: ComponentLocations.CART.CLICK_OUTSIDE,
            //   page_path: pagePath,
            // })}
          >
            <ConnectModal showModal={showModal} handleClose={() => setShowModal(false)} currency={currency} />
            <aside
              className={cx(
                'fixed top-0 right-0 bg-grayscale-white w-[620px] max-w-full h-full grid grid-rows-[auto_1fr_auto] pt-[28px] translate-x-full transition-transform ease-out duration-300',
                { 'translate-x-0': showCart }
              )}
            >
              <div className="flex items-center justify-between px-20 py-0 mb-20 md:px-40">
                <h1 className="m-0 text-[40px]">Cart</h1>
                <Button
                  variant="tertiary-neutral"
                  onClick={() => {
                    dispatch(setCartVisible(false));
                    tracker.trackEvent(
                      new CartInteraction({
                        action: 'close',
                        component_location: ComponentLocations.CART.HEADER,
                        cart_products: trackingProducts,
                        cart_value: getTrackingProductsTotal(trackingProducts),
                      })
                    );
                  }}
                  aria-label="Hide cart"
                >
                  <X />
                </Button>
              </div>
              <CartContent openModal={() => setShowModal(true)} />
            </aside>
          </div>
        </FocusLock>
      </ClientOnly>
    </>
  );
};

export default Cart;
