import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { X } from 'phosphor-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SVG from 'src/components/SVG';
import SanityBlockComponent from 'src/helper-components/SanityBlockComponent';
import { isRefExternal } from 'src/helpers/formattingHelpers';
import { RM110ReferralDiscount, rm110ReferralDiscountSelector } from 'src/helpers/rm110ReferralDiscount';
import { ConnectPromotionDiscount, useConnectPromotion } from 'src/hooks/useConnectPromotion';
import { EmergencyBanner } from 'src/queries/groq/emergencyBanner';
import * as notificationbarActions from 'src/redux/actions/NotificationBarActions';
import { State } from 'src/redux/reducers';

import { Typography } from '@remarkable/ark-web';
import { Countdown } from 'src/components/Countdown/Countdown';
import { usePriceFormatter } from 'src/helpers/formattingHelpers';

interface Props {
  addBodyPadding?: boolean;
  emergencyBanner?: EmergencyBanner;
}

function showEmergencyBanner(banner: EmergencyBanner | undefined, pathname: string) {
  const visibleForPathname = banner?.active && banner?.showForUrl?.includes(pathname);
  const shouldReplaceReferral =
    banner?.active && banner?.showForUrl?.includes('/referral') && pathname.startsWith('/referral');

  return visibleForPathname || shouldReplaceReferral;
}

function showReferralBanner(referralCode: string, pathname: string) {
  return referralCode && !pathname.includes('/checkout');
}

function showConnectPromotionBanner(connectPromotionDiscount: ConnectPromotionDiscount, pathname: string) {
  return connectPromotionDiscount?.promotionCode && !pathname.includes('/checkout');
}

const NotificationBar: React.FC<Props> = (props: Props) => {
  const router = useRouter();
  const pathname = router.asPath;

  const thisRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const country = useSelector((state: State) => state.countryDetector.country);
  const dismissed = useSelector((state: State) => state.notificationbar.dismissed);
  const referralCode = useSelector((state: State) => state.store2.referralCode);
  const maybeRM110Discount = useSelector(rm110ReferralDiscountSelector);

  const connectPromotionDiscount = useConnectPromotion();

  const [height, setHeight] = useState(0);

  const { emergencyBanner } = props;

  const setSize = () => {
    if (thisRef && thisRef.current) {
      setHeight(thisRef.current?.scrollHeight);
    }
  };

  const dismissedClicked = () => {
    dispatch({ type: notificationbarActions.DISMISS_NOTIFICATION_BAR });
  };

  const getColorStyle = (): string => {
    // Order matters, should equal the one of 'getDiscountText'
    if (showEmergencyBanner(emergencyBanner, pathname) || showReferralBanner(referralCode, pathname)) {
      return 'bg-brand-brown-600 text-white';
    }

    if (showConnectPromotionBanner(connectPromotionDiscount, pathname)) {
      return 'bg-brand-sand-300 text-black';
    }

    return 'bg-brand-brown-600 text-white';
  };

  const priceFormatter = usePriceFormatter();

  const getDiscountText = useCallback(
    (
      referralCode: string,
      maybeRM110Discount: RM110ReferralDiscount,
      connectPromotionDiscount: ConnectPromotionDiscount
    ): JSX.Element | null => {
      const formattedAmount = priceFormatter(maybeRM110Discount?.amount ?? 0, maybeRM110Discount?.currency ?? 'USD');

      // Check emergency content
      if (showEmergencyBanner(emergencyBanner, pathname)) {
        // Assemble content
        const emContent = <SanityBlockComponent input={emergencyBanner?.text} variables={{}} />;
        const refExternal = emergencyBanner?.link && isRefExternal(emergencyBanner.link);

        if (refExternal) {
          return (
            <a href={emergencyBanner?.link ?? undefined} target="_blank" rel="noopener noreferrer">
              {emContent}
            </a>
          );
        }

        if (emergencyBanner?.link) {
          return (
            <Link href={emergencyBanner.link} legacyBehavior>
              {emContent}
            </Link>
          );
        }

        return <>{emContent}</>;
      }

      // Check for referral code
      if (showReferralBanner(referralCode, pathname)) {
        if (maybeRM110Discount) {
          return (
            <div className="flex" data-cy="referral-banner">
              <SVG name="referral_tag_white" assetsFolder="ReferralIcons" height={20} className="mb-4" />
              <span style={{ display: 'inline-block', width: '0.5em' }}></span>
              <span>
                <span style={{ fontWeight: 700 }}>Referral code active</span> - Save{' '}
                <span data-cy="discount-amount" style={{ fontWeight: 700 }}>
                  {formattedAmount.formatted}
                </span>{' '}
                on your reMarkable 2
              </span>
            </div>
          );
        }
        return <span>Referral code active. Discount will be applied at checkout.</span>;
      }

      // Check for connect promotion discount code
      if (showConnectPromotionBanner(connectPromotionDiscount, pathname)) {
        if (connectPromotionDiscount?.formatted) {
          return (
            <div className="inline-flex items-center">
              <span className="flex flex-row items-center">
                <Typography as="p" variant="body-sm-book" className="mr-12 font-medium tracking-wider">
                  OFFER ENDS IN:
                </Typography>
                <Countdown fontSize={20} endDate={new Date('2023-08-25T07:00:00Z')} />
              </span>
            </div>
          );
        }
        return <span>Connect offer active. Discount will be applied at checkout.</span>;
      }

      return null;
    },
    [emergencyBanner, pathname, priceFormatter]
  );

  useEffect(() => {
    setSize();
  });

  const content = getDiscountText(referralCode, maybeRM110Discount, connectPromotionDiscount);
  const showNotificationBar = content && !dismissed;

  if (!content) {
    return null;
  }

  function TopPadding() {
    return <div className={'transition-[padding-top] ease-in-out delay-500 pt-0'} />;
  }

  return (
    <>
      <TopPadding />
      <div
        id="notification-bar"
        ref={thisRef}
        style={{ minHeight: `${height || 56}px` }}
        className={cx('relative overflow-hidden z-30 items-center justify-center', getColorStyle(), {
          flex: showNotificationBar,
          hidden: !showNotificationBar,
        })}
      >
        {country && (
          <div className={'text-center px-[60px] py-8'}>
            <Typography as="span" variant="body-sm-book" className={getColorStyle()}>
              {content}
            </Typography>
          </div>
        )}
        <button
          className={'md:right-[calc((100%/12)-10px)] absolute right-0 p-20 m-0 flex justify-center'}
          aria-label="Dismiss notification"
          onClick={dismissedClicked}
        >
          <X size={20} weight="fill" />
        </button>
      </div>
    </>
  );
};

export default NotificationBar;
