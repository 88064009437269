import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import ClientOnly from '../ClientOnly';

type FontSize = 24 | 20 | 14;

export type Props = {
  fontSize: FontSize;
  endDate: Date;
};

interface Sizes {
  letterFontSize: string;
  marginTimeUnit: string;
  marginLetter: string;
}

const padTime = (time: number) => String(time).padStart(2, '0');

const getSizes = (fontSize: FontSize): Sizes => {
  switch (fontSize) {
    case 24: {
      return { letterFontSize: 'text-14', marginTimeUnit: 'mr-[8px]', marginLetter: 'ml-4' };
    }
    case 20: {
      return { letterFontSize: 'text-14', marginTimeUnit: 'mr-[12px]', marginLetter: 'ml-4' };
    }
    case 14: {
      return { letterFontSize: 'text-14', marginTimeUnit: 'mr-[8px]', marginLetter: 'ml-4' };
    }
  }
};

const formatTimeWithLetters = (part: number, letter: string, fontSize: FontSize) => {
  const sizes = getSizes(fontSize);
  return (
    <span className={`${sizes.marginTimeUnit} flex items-center`}>
      <ClientOnly>
        <b
          style={{ fontSize: fontSize }}
          className={classNames('font-demi tracking-wider', { 'mb-2': fontSize === 20 }, { 'mb-4': fontSize === 24 })}
        >
          {padTime(part)}
        </b>
      </ClientOnly>

      <span className={`${sizes.letterFontSize} ${sizes.marginLetter} font-medium tracking-wider`}>{letter}</span>
    </span>
  );
};

export const Countdown: React.FC<Props> = ({ fontSize = 20, endDate }) => {
  const fixedDate = new Date(endDate);
  fixedDate.setHours(endDate.getHours()); // TODO: can we remove this?

  const [timer, setTimer] = useState((fixedDate.getTime() - Date.now()) / 1000);

  let minutes, hours, days;
  //We don't want a countdown with negative numbers, set to zero's if the end time has passed for some reason
  if (endDate > new Date()) {
    minutes = Math.floor((timer / 60) % 60);
    hours = Math.floor((timer / (60 * 60)) % 24);
    days = Math.floor(timer / (60 * 60 * 24));
  } else {
    minutes = 0;
    hours = 0;
    days = 0;
  }

  // Update time when end date changes:
  useEffect(() => {
    const fixedDate = new Date(endDate);
    fixedDate.setHours(endDate.getHours());
    setTimer((fixedDate.getTime() - Date.now()) / 1000);
  }, [endDate]);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <span className="flex">
      {formatTimeWithLetters(days, ' DAYS ', fontSize)}
      {formatTimeWithLetters(hours, ' HRS ', fontSize)}
      {formatTimeWithLetters(minutes, ' MIN ', fontSize)}
    </span>
  );
};
