import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatLocalizedPrice } from 'src/helpers/formattingHelpers';
import { useCountryData } from 'src/hooks/useCountryData';
import { State } from 'src/redux/reducers';

export type ConnectPromotionDiscount =
  | { promotionCode: string; amount: number; currency: string; formatted: string }
  | undefined;

export function useConnectPromotion(): ConnectPromotionDiscount {
  const promotionCode = useSelector((state: State) => state.store2.promotionCode);
  const connectPromotionDiscount = useSelector((state: State) => state.store2.connectPromotionDiscount) ?? {};
  const { currency, sanityCountry } = useCountryData();

  const amount = connectPromotionDiscount[currency] ?? 0;
  const formattedAmount = formatLocalizedPrice(amount, sanityCountry.lang, sanityCountry.value, sanityCountry.currency);

  return useMemo(() => {
    if (!promotionCode || !amount || amount <= 0) {
      return undefined;
    }

    return {
      promotionCode,
      amount,
      currency,
      formatted: formattedAmount,
    };
  }, [amount, currency, formattedAmount, promotionCode]);
}
