import { State } from 'src/redux/reducers';

import { formatLocalizedPrice } from './formattingHelpers';
import { getCountryInfoByValue } from './storeHelpers';

export type RM110ReferralDiscount = { amount: number; currency: string; formatted: string } | undefined;

export const rm110ReferralDiscountSelector = (state: State): RM110ReferralDiscount => {
  const rm110ReferralDiscounts = state.store2.rm110ReferralDiscounts;
  const sanityCountryData = state.staticQuery.countryData;
  const country = state.countryDetector.country;
  const countryData = getCountryInfoByValue(sanityCountryData, country);
  const {
    currency: { value: currency },
  } = countryData;

  const referralCode = state.store2.referralCode;
  if (referralCode && rm110ReferralDiscounts && rm110ReferralDiscounts[currency]) {
    const amount = rm110ReferralDiscounts[currency];

    return {
      amount,
      currency,
      formatted: formatLocalizedPrice(amount, countryData.lang, country, countryData.currency),
    };
  }
  return;
};
